var _a;
import { __decorate, __metadata } from "tslib";
import { User } from '@/shared/authentication';
import ScrollablePanel from '@/shared/components/scrollable-panel/scrollable-panel.vue';
import { UserRole, UserType, Department } from '@/shared/schema/globalTypes';
import Vue from 'vue';
import Component from 'vue-class-component';
import { RouterLink } from 'vue-component-router';
import { Prop } from 'vue-property-decorator';
import Icon from '@/shared/components/icon/icon--solid.vue';
import Box from '@/shared/layout/box/layout-box.vue';
import NavSection from '@/shared/ui/nav-section.vue';
import { resolveDepartments } from '@/shared/util';
import { intersection } from 'lodash';
let MainNav = class MainNav extends Vue {
    menuOpen;
    currentUser;
    hasRole;
    canGenerateInvoicesAndReceipts;
    UserRole = UserRole;
    dashboardSection = false;
    get isDS() {
        return this.currentUser.type !== UserType.Fundraiser && this.currentUser.type !== UserType.DepartmentManager;
    }
    get showDepts() {
        return this.currentUser.type !== UserType.DepartmentManager;
    }
    get isCEO() {
        return this.currentUser.type === UserType.CEO;
    }
    get showNotificationSettings() {
        const userTypes = [UserType.CEO, UserType.DepartmentManager, UserType.Fundraiser, UserType.RegionManager];
        return false; // (this.currentUser && userTypes.includes(this.currentUser.type)) || false
    }
    get showDS() {
        if (this.hasRole(UserRole.ViewNewDonors))
            return true;
        if (this.hasRole(UserRole.ViewPending))
            return true;
        if (this.hasRole(UserRole.ManageRM))
            return true;
        if (this.hasRole(UserRole.ViewQiuckLookup))
            return true;
        if (this.hasRole(UserRole.ViewRecurringPayments))
            return true;
        if (this.canGenerateInvoicesAndReceipts())
            return true;
        return this.showDepts;
    }
    get tooltopClasses() {
        return this.menuOpen ? 'hide-important' : '';
    }
    truncateHousehold(str) {
        if (str.length > 30) {
            return str.substring(0, 27) + '...';
        }
        else {
            return str;
        }
    }
    get belongsToTeachCoalition() {
        const userDepts = this.currentUser.departments ? resolveDepartments(this.currentUser.departments) : [];
        return intersection(userDepts, resolveDepartments(Department.Teach_AdminGroup)).length ? true : false;
    }
};
__decorate([
    Prop(Boolean),
    __metadata("design:type", Boolean)
], MainNav.prototype, "menuOpen", void 0);
__decorate([
    Prop(),
    __metadata("design:type", typeof (_a = typeof User !== "undefined" && User) === "function" ? _a : Object)
], MainNav.prototype, "currentUser", void 0);
__decorate([
    Prop({ type: Function }),
    __metadata("design:type", Function)
], MainNav.prototype, "hasRole", void 0);
__decorate([
    Prop({ type: Function }),
    __metadata("design:type", Object)
], MainNav.prototype, "canGenerateInvoicesAndReceipts", void 0);
MainNav = __decorate([
    Component({
        components: {
            RouterLink,
            NavSection,
            Icon,
            Box,
            ScrollablePanel
        }
    })
], MainNav);
export default MainNav;
