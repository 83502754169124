import Box from '@/shared/layout/box/layout-box.vue';
import layoutText from '@/shared/layout/text/layout-text.vue';
import UIButton from '@/shared/ui/button/ui-button.vue';
import { useMutation } from '@vue/apollo-composable';
import { computed, defineComponent, reactive } from 'vue';
import { useCurrentUser } from '@/shared/composables/useCurrentUser';
import { loadSummary } from '../../summary/data';
import { notify } from '@/shared/notifications';
import layoutFormRow from '@/shared/ui/forms/form-row/layout-form-row.vue';
import UILabel from '@/shared/ui/forms/label/ui-label.vue';
import UISelect from '@/shared/ui/forms/select/ui-select.vue';
import GENERATE_AND_SEND_RECEIPT from './generate-and-send-receipt.graphql';
import Icon from '@/shared/components/icon/icon.vue';
import FormRow from '@/shared/ui/forms/form-row/layout-form-row.vue';
import UIInput from '@/shared/ui/forms/input/ui-input.vue';
import UITextarea from '@/shared/ui/forms/textarea/ui-textarea.vue';
import GET_FUNDRAISERS from '../get-pledge-fundraisers.graphql';
import { useQuery, useResult } from '@vue/apollo-composable';
import UICheckbox from '@/shared/ui/forms/checkbox/checkbox.vue';
export default defineComponent({
    components: {
        Box,
        FormRow,
        layoutText,
        UIButton,
        layoutFormRow,
        UILabel,
        UISelect,
        Icon,
        UIInput,
        UITextarea,
        UICheckbox
    },
    props: {
        pledgeId: String,
        paymentId: String,
        donorId: String
    },
    setup(props, { emit }) {
        const { accessToken } = useCurrentUser();
        const { loading: loadingSummary, summary } = loadSummary({ id: props.donorId });
        const state = reactive({
            emails: computed(() => summary?.value?.emails.map(e => e.email) || []),
            primaryEmail: computed(() => summary?.value?.emails.find(e => e.id === summary?.value?.billingEmailID)?.email ||
                summary?.value?.emails.find(e => e.id === summary?.value?.primaryEmailID)?.email),
            selectedEmail: null,
            from: null,
            fromName: '',
            subject: '',
            cc: '',
            bcc: '',
            message: null,
            fundraiser: null,
            removeSignature: false
        });
        const { result, loading } = useQuery(GET_FUNDRAISERS, () => ({ id: props.pledgeId }), {});
        const fundraisers = useResult(result, null, data => {
            if (!data.getPledgeFundraisers)
                return [];
            return data.getPledgeFundraisers.map(user => ({
                id: user.id,
                name: `${user.firstName} ${user.lastName}`,
                email: user.email
            }));
        });
        function onFundraiserSelected(fundraiser) {
            if (!fundraiser)
                return;
            state.from = fundraiser.email;
            state.fromName = fundraiser.name;
            state.fundraiser = null;
        }
        function onEmailChange(email) {
            if (!email)
                return;
            state.selectedEmail = email;
        }
        const { mutate: sendReceipt, loading: sendingEmail } = useMutation(GENERATE_AND_SEND_RECEIPT);
        async function submit() {
            const email = state.selectedEmail || state.primaryEmail;
            if (!email || !props.pledgeId)
                return;
            const response = await sendReceipt({
                pledgeId: props.pledgeId,
                paymentId: props.paymentId || undefined,
                emailParams: {
                    email,
                    from: state.from || undefined,
                    fromName: state.fromName || undefined,
                    subject: state.subject || undefined,
                    cc: state.cc || undefined,
                    bcc: state.bcc || undefined,
                    message: state.message || undefined,
                    removeSignature: state.removeSignature
                }
            });
            const result = response?.data?.generateAndSendReceipt;
            if (result) {
                notify('Receipt was sent successfully');
            }
            emit('done');
        }
        function cancelEdit() {
            emit('done');
        }
        function getReceiptLink() {
            return process.env.VUE_APP_API.replace('/graphql', `/pdf/pledgeReceipt/${props.pledgeId}${props.paymentId ? '/' + props.paymentId : ''}?token=${accessToken}&doNotSend`);
        }
        return {
            submit,
            cancelEdit,
            getReceiptLink,
            onEmailChange,
            state,
            email: computed(() => state.selectedEmail || state.primaryEmail),
            sendingEmail,
            fundraisers,
            onFundraiserSelected
        };
    }
});
