import Form from './form.vue';
import { compose, acceptProps } from 'vue-compose';
import LOAD_PLEDGE from './load-pledge.graphql';
import graphql from '@/shared/graphql';
import withLoader from '../loading/with-loader';
import { BillingPlanDistributionType } from '@/shared/schema/globalTypes';
import startCase from 'lodash/startCase';
import { dateToMonthDayYear } from '@/shared/util';
import { dateScalarToDate } from '@velocity/shared/dist/utils';
function getDistributionAmount(billingPlan) {
    if (billingPlan.type === BillingPlanDistributionType.ByQuantity) {
        return billingPlan.installments.length;
    }
    return billingPlan.installments[0].amount;
}
const loadPledge = graphql(LOAD_PLEDGE, {
    skip(props) {
        return !props.pledgeId;
    },
    options(props) {
        return {
            variables: {
                id: props.pledgeId
            }
        };
    },
    //@ts-ignore
    props(props) {
        if (!props.data || props.loading || !props.data.pledge) {
            return {
                donor: props.donor,
                pledge: null,
                payments: [],
                refreshData: Function,
                audit: []
            };
        }
        const pledge = props.data.pledge;
        return {
            refreshData: props.data.refetch,
            donor: props.donor ||
                {
                    ...props.data.pledge.donor,
                    isHousehold: props.data.pledge.donor.__typename == 'Household'
                },
            audit: props.data.pledge.auditLog,
            turnaroundPayments: pledge.applications
                .filter(x => x.turnedAround)
                .map(pa => {
                const logItem = pa.payment.auditLog.find(l => l.__typename == 'PaymentTurnedAroundSetAuditLogItem' &&
                    l.data?.pledgeId == pledge.id &&
                    l.data.turnedAround);
                return {
                    date: dateScalarToDate(logItem.date),
                    amount: pa.amount,
                    id: pa.paymentId,
                    user: `${logItem.user?.firstName} ${logItem.user?.lastName}`
                };
            }),
            payments: pledge.applications.map(x => ({
                id: x.paymentId,
                amount: x.amount,
                originalAmount: x.originalAmount,
                batchId: x.payment.batchId,
                status: x.payment.status,
                notes: x.payment.notes,
                type: x.payment.type,
                voidReason: x.payment.voidReason,
                date: dateToMonthDayYear(dateScalarToDate(x.payment.date))
            })),
            pledge: {
                id: pledge.id,
                shortId: pledge.shortId,
                type: pledge.type,
                amount: {
                    amount: pledge.originalAmount ? pledge.originalAmount : pledge.amount,
                    currency: pledge.currency
                },
                totalPaid: pledge.totalPaid,
                convertedAmount: pledge.amount,
                donor: props.data.pledge
                    ? {
                        ...props.data.pledge.donor,
                        isHousehold: props.data.pledge.donor.__typename == 'Household'
                    }
                    : props.donor,
                grant: pledge.grant
                    ? {
                        amount: pledge.grant.amountApproved || 0,
                        currency: pledge.grant.currency,
                        description: pledge.grant.projectName || 'No Project Name',
                        id: pledge.grant.id
                    }
                    : null,
                campaign: pledge.campaign
                    ? {
                        id: pledge.campaign.id,
                        label: pledge.campaign.name
                    }
                    : null,
                subcampaign: pledge.subCampaign
                    ? {
                        id: pledge.subCampaign.id,
                        label: pledge.subCampaign.name
                    }
                    : null,
                dafPayFund: pledge.dafPayFund,
                dafPayTrackingId: pledge.dafPayTrackingId,
                tributeText: pledge.tributeText,
                fiscalYear: pledge.fiscalYear,
                accountingFY: pledge.accountingFY,
                calendarYear: pledge.calendarYear,
                date: dateToMonthDayYear(dateScalarToDate(pledge.date)),
                description: pledge.notes,
                goal: pledge.goal ? { id: pledge.goal.id, label: pledge.goal.title } : null,
                fundraisers: pledge.fundraisers.map(x => ({
                    fundraiser: {
                        id: x.fundraiser.id,
                        label: `${x.fundraiser.firstName} ${x.fundraiser.lastName}`
                    },
                    percent: x.percent || 100
                })),
                invoice_template: null /*missing*/,
                sent_status: null /*missing*/,
                backupFiles: pledge.backupFiles.map(f => ({
                    uploadedFile: {
                        file: f.fileUrl,
                        status: 'uploaded'
                    },
                    description: f.description
                })),
                ...(pledge.subCampaign
                    ? {
                        subCampaign: {
                            id: pledge.subCampaign.id,
                            label: pledge.subCampaign.name
                        }
                    }
                    : {}),
                department: pledge.department,
                currency: pledge.currency,
                billingPlan: pledge.billingPlan && pledge.billingPlan.installments.length > 0
                    ? {
                        autoBill: pledge.billingPlan.autoBill,
                        cardOnFile: pledge.billingPlan.cardOnFile || undefined,
                        distributionType: pledge.billingPlan ? pledge.billingPlan.type : null,
                        interval: pledge.billingPlan ? pledge.billingPlan.interval : null,
                        installments: !pledge.billingPlan
                            ? null
                            : pledge.billingPlan.installments.map(x => ({
                                amount: x.amount,
                                date: dateToMonthDayYear(dateScalarToDate(x.date)),
                                paid: x.paid
                            })),
                        planStart: !pledge.billingPlan
                            ? null
                            : dateToMonthDayYear(dateScalarToDate(pledge.billingPlan.installments[0].date)),
                        distributionAmount: !pledge.billingPlan
                            ? null
                            : getDistributionAmount(pledge.billingPlan)
                    }
                    : null,
                hasTurnaround: !!pledge.turnaround,
                taDepartment: pledge.turnaround
                    ? {
                        id: pledge.turnaround.department,
                        label: startCase(pledge.turnaround.department)
                    }
                    : null,
                taCampaign: pledge.turnaround
                    ? {
                        id: pledge.turnaround.campaign.id,
                        label: pledge.turnaround.campaign.name
                    }
                    : null,
                taSubcampaign: pledge.turnaround && pledge.turnaround.subCampaign
                    ? {
                        id: pledge.turnaround.subCampaign.id,
                        label: pledge.turnaround.subCampaign.name
                    }
                    : null,
                uncollectible: pledge.uncollectible,
                uncollectibleReason: pledge.uncollectibleReason,
                conditionalPledge: pledge.conditionalPledge,
                accountingPledge: pledge.accountingPledge,
                sendInvoiceVia: pledge.sendInvoiceVia,
                shouldDSPrintAnInvoice: !!pledge.sendInvoiceVia
            }
        };
    }
});
export default compose(loadPledge, withLoader(), acceptProps(['pledgeId', 'pledge']))(Form);
